import * as React from "react"
import Seo from "../components/seo"
import Switch from "./../components/switch"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <div className="container flex flex-col justify-center min-h-screen m-auto text-center min-w-screen">
      <Switch />
      <h1
        id="fancy-text"
        className="text-xl font-extrabold tracking-wide md:text-4xl lg:text-6xl dark:text-gray-50"
      >
        <span role="img" aria-label="Construction Sign">
          🚧
        </span>{" "}
        Under Construction{" "}
        <span role="img" aria-label="Construction Sign">
          🚧
        </span>
      </h1>
      <p className="text-lg md:text-2xl lg:text-3xl dark:text-gray-100">
        Please come back soon!
      </p>
    </div>
  </>
)

export default IndexPage
